import React, { useState, useEffect } from "react";
import "./bookings.css";
import { db } from "./firebase";
import { collection, addDoc } from "firebase/firestore";

const Bookings = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const submitToFirestore = async () => {
      if (isSubmitted) {
        // Your form data
        const yourName = document.getElementsByName("yourName")[0].value;
        const phoneNumber = document.getElementsByName("phoneNumber")[0].value;
        const email = document.getElementsByName("email")[0].value;
        const checkIn = document.getElementsByName("checkIn")[0].value;
        const checkOut = document.getElementsByName("checkOut")[0].value;
        const adult = document.getElementsByName("adult")[0].value;
        const children = document.getElementsByName("children")[0].value;
        const rooms = document.getElementsByName("rooms")[0].value;

        const bookingData = {
          yourName,
          phoneNumber,
          email,
          checkIn,
          checkOut,
          adult,
          children,
          rooms,
          bookingGranted: false,
        };

        try {
          // Store the booking data in the Firestore database
          const docRef = await addDoc(collection(db, "bookings"), bookingData);
          console.log("Booking data added to Firestore with ID: ", docRef.id);
        } catch (error) {
          console.error("Error adding booking data to Firestore: ", error);
        }

        // Reset form fields
        document.querySelector(".form").reset();
        setIsSubmitted(false);
      }
    };

    submitToFirestore();
  }, [isSubmitted]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
  };

  const handleDismiss = () => {
    setIsSubmitted(false);
  };

  return (
    <div className="container">
      <h2>Enjoy Your Holiday @Eziana Palm Hotel</h2>
      <span style={{ textAlign: "center" }}>Book with us</span>

      <form onSubmit={handleFormSubmit} className="form">
        <div className="flex_space">
          <input type="text" placeholder="Your Name" name="yourName" required />
          <input type="text" placeholder="Phone Number" name="phoneNumber" required />
        </div>
        <div className="flex_space">
          <input type="text" placeholder="Email" name="email" required />
        </div>
        <div className="flex_space">
          <input type="date" placeholder="Check In" name="checkIn" required />
          <input type="date" placeholder="Check Out" name="checkOut" required />
        </div>
        <div className="flex_space">
          <input type="number" placeholder="Adult(s)(18+)" name="adult" required />
          <input type="number" placeholder="Children(0-17)" name="children" required />
        </div>
        <input type="text" placeholder="Roomtype e.g Executive,deluxe or twin beds" name="rooms" required />
        <input type="submit" value="Submit" className="submit" />
      </form>

      {isSubmitted && (
        <div className="notification-popup">
          <h1>Your Booking has been received Successfully!</h1>
          <p>Thank you for booking Eziana Palm Hotel. Have a wonderful stay.</p>
          <button onClick={handleDismiss}>OK</button>
        </div>
      )}
    </div>
  );
};

export default Bookings;
