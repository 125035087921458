const Data = [
  {
    image: "Images/slider-1.jpg",
  },
  {
    image: "Images/slider-2.jpg",
  },
  {
    image: "Images/slider-3.jpg",
  },
]
export default Data
